import { TOTAL_ACCESS_URGENT_CARE_GROUP_ID } from '../config/groups';

// actions
export const ACTION_NOOP = 'action/NOOP';

export const SET_RUNTIME_VARIABLE = 'runtime/SET_RUNTIME_VARIABLE';

export const REHYDRATE = 'persist/REHYDRATE';

export const BOOKING_RESPONSE_RECEIVED = 'newBooking/RESPONSE_RECEIVED';
export const BOOKING_REQUEST_SUBMITTED = 'newBooking/REQUEST_SUBMITTED';
export const BOOKING_REQUEST_FAILED = 'newBooking/REQUEST_FAILED';
export const BOOKING_REQUEST_NOT_SUBMITTED = 'newBooking/REQUEST_NOT_SUBMITTED';
export const BOOKING_SIGN_IN_FORM_SUBMITTED = 'newBooking/BOOKING_SIGN_IN_FORM_SUBMITTED';
export const BOOKING_CLEAR_TIME_AND_PLACE = 'newBooking/CLEAR_TIME_AND_PLACE';

export const LOOK_UP_SET_TYPE = 'lookUp/SET_TYPE';
export const LOOK_UP_SET_SUBMITTING = 'lookUp/SET_SUBMITTING';
export const LOOK_UP_BOOKINGS_RECEIVED = 'lookUp/BOOKINGS_RECEIVED';
export const LOOK_UP_BOOKINGS_ERROR = 'lookUp/BOOKINGS_ERROR';
export const LOOK_UP_USER_PROFILES_RECEIVED = 'lookUp/USER_PROFILES_RECEIVED';
export const LOOK_UP_USER_PROFILES_ERROR = 'lookUp/USER_PROFILES_ERROR';

export const LOCATION_RESPONSE_RECEIVED = 'location/RESPONSE_RECEIVED';
export const LOCATION_FAILED = 'location/ERROR';
export const LOADING_LOCATION = 'location/LOADING';
export const LOCATION_PATCH_SUCCESS = 'location/PATCH_SUCCESS';
export const LOCATION_PATCH_ERROR = 'location/PATCH_ERROR';
export const LOCATION_PATCH_STATUS_CLEAR = 'location/PATCH_STATUS_CLEAR';
export const LOCATION_WAIT_LIST_COUNT_RECEIVED = 'location/WAIT_LIST_COUNT_RECEIVED';
export const LOADING_LOCATION_SERVICE_PREFERENCES = 'location/LOADING_SERVICE_PREFERENCES';
export const LOCATION_RECEIVE_SERVICE_PREFERENCES_PRACTICES =
  'location/RECEIVE_SERVICE_PREFERENCES_PRACTICES';
export const LOCATION_RECEIVE_SERVICE_PREFERENCES_APPOINTMENTS =
  'location/RECEIVE_SERVICE_PREFERENCES_APPOINTMENTS';
export const LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR =
  'location/RECEIVE_SERVICE_PREFERENCES_ERROR';
export const RESET_LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR =
  'location/RESET_LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR';
export const LOCATION_RECEIVE_SPECIAL_OPERATING_HOURS = 'location/RECEIVE_SPECIAL_OPERATING_HOURS';
export const LOCATION_RECEIVE_OPERATING_HOURS = 'location/RECEIVE_OPERATING_HOURS';

export const LOGIN_CLINICS_RECEIVED = 'login/CLINICS_RECIEVED';
export const LOGIN_LOGOUT = 'login/LOGOUT';
export const LOGIN_ACCOUNT_RECEIVED = 'login/ACCOUNT_RECEIVED';
export const PUBNUB_AUTH_RECEIVED = 'login/AUTH_RECEIVED';
export const PUBNUB_AUTH_ERROR = 'login/AUTH_ERROR';
export const LAST_COMPLETED_NPS_UPDATED = 'login/LAST_COMPLETED_NPS_UPDATED';

export const INSURANCE_FORM_SUBMISSION = 'insurance/FORM_SUBMISSION';
export const INSURANCE_FORM_SUBMITTED = 'insurance/FORM_SUBMITTED';
export const INSURANCE_FORM_NOT_SUBMITTED = 'insurance/FORM_NOT_SUBMITTED';
export const INSURANCE_OCR_DATA = 'insurance/OCR_DATA';
export const INSURANCE_AUTOCOMPLETE = 'insurance/AUTOCOMPLETE';

export const INSURERS_RECEIVED = 'insurers/RECEIVED';
export const INSURERS_ERROR = 'insurers/ERROR';

export const IMAGE_DATA_SET = 'imageData/SET';
export const IMAGE_DATA_SET_FRONT = 'imageData/SET_FRONT';
export const IMAGE_DATA_SET_BACK = 'imageData/SET_BACK';
export const IMAGE_DATA_CLEAR = 'imageData/CLEAR';

export const INSURANCE_CARD_IMAGE_SET = 'insuranceCardImage/SET';

export const INSURANCE_CHECK_SET_REQUEST = 'insurance/SET_REQUEST';
export const INSURANCE_CHECK_SUBMITTING = 'insurance/SUBMITTING';
export const INSURANCE_CHECK_RESPONSE_RECEIVED = 'insurance/RESPONSE_RECEIVED';
export const INSURANCE_CHECK_ERROR = 'insurance/ERROR';
export const INSURANCE_TOGGLE_TREE = 'insurance/TOGGLE_TREE';
export const INSURANCE_RESET_FORM = 'insurance/RESET_FORM';

export const ELIGIBILITY_CHECK_RECEIVED = 'eligibilityCheck/RECEIVED';
export const ELIGIBILITY_CHECK_ERROR = 'eligibilityCheck/ERROR';
export const ELIGIBILITY_CHECK_SUBMITTED = 'eligibilityCheck/SUBMITTED';

export const ELIGIBILITY_FEEDBACK_SUCCESS = 'eligibilityCheck/ELIGIBILITY_FEEDBACK_SUCCESS';
export const ELIGIBILITY_FEEDBACK_FAILURE = 'eligibilityCheck/ELIGIBILITY_FEEDBACK_FAILURE';
export const ELIGIBILITY_FEEDBACK_SUBMIT = 'eligibilityCheck/ELIGIBILITY_FEEDBACK_SUBMIT';

export const ELIGIBILITY_FACESHEET_RECEIVED = 'eligibilityCheck/FACESHEET_RECEIVED';
export const ELIGIBILITY_FACESHEET_ERROR = 'eligibilityCheck/FACESHEET_ERROR';
export const ELIGIBILITY_FACESHEET_RESET = 'eligibilityCheck/FACESHEET_RESET';

export const QUEUE_SET_LOCATION_IDS = 'queue/SET_LOCATION_IDS';
export const QUEUE_ADD_LOCATION_IDS = 'queue/ADD_LOCATION_IDS';
export const QUEUE_SET_TAB = 'queue/SET_TAB';
export const QUEUE_TOGGLE_NOTES_MODAL = 'queue/TOGGLE_NOTES_MODAL';

export const ADMIN_BOOKINGS_SET_TAB = 'admin/SET_TAB';
export const ADMIN_BOOKINGS_SEARCH_RESULTS_RECEIVED = 'admin/SEARCH_RESULTS_RECEIVED';
export const ADMIN_BOOKINGS_SEARCH_RESULTS_ERROR = 'admin/SEARCH_RESULTS_ERROR';
export const ADMIN_BOOKINGS_CLEAR_SEARCH_RESULTS = 'admin/CLEAR_SEARCH_RESULTS';
export const ADMIN_BOOKINGS_SET_ASSIGN_LOCATION_MODAL_SELECTED_INDEX =
  'admin/SET_ASSIGN_LOCATION_MODAL_SELECTED_INDEX';
export const ADMIN_BOOKINGS_SET_SEARCH_FILTERS = 'admin/SET_SEARCH_FILTERS';
export const ADMIN_CALL_CENTER_SEARCH_PARAMS = 'admin/CALL_CENTER_SEARCH_PARAMS';

export const PARTIAL_UPDATE_EXISTING_BOOKING = 'bookings/PARTIAL_UPDATE_EXISTING';
export const BOOKINGS_RECEIVED = 'bookings/RECEIVED';
export const BOOKINGS_REFRESHED = 'bookings/REFRESHED';
export const BOOKINGS_SEARCH_RECEIVED = 'bookings/SEARCH_RECEIVED';
export const BOOKINGS_COUNT_RECEIVED = 'bookings/COUNT_RECEIVED';
export const BOOKINGS_ERROR = 'bookings/ERROR';
export const BOOKINGS_LOADING = 'bookings/LOADING';
export const GET_BOOKING_SUCCESS = 'bookings/GET_BOOKING_SUCCESS';
export const GET_BOOKING_ERROR = 'bookings/GET_BOOKING_ERROR';
export const UPDATE_BOOKING_SUBMITTING = 'bookings/UPDATE_BOOKING_SUBMITTING';
export const UPDATE_BOOKING_SUCCESS = 'bookings/UPDATE_BOOKING_SUCCESS';
export const TRANSFER_BOOKING_SUCCESS = 'bookings/TRANSFER_BOOKING_SUCCESS';
export const UPDATE_USER_PROFILE_SUCCESS = 'bookings/UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'bookings/UPDATE_USER_PROFILE_ERROR';
export const UPDATE_BOOKING_ERROR = 'bookings/UPDATE_BOOKING_ERROR';
export const CREATE_BOOKING_SUCCESS = 'bookings/CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_ERROR = 'bookings/CREATE_BOOKING_ERROR';
export const CLEAR_BOOKING_ERROR = 'bookings/CLEAR_BOOKING_ERROR';
export const CLEAR_MOST_RECENT_BOOKING = 'bookings/CLEAR_MOST_RECENT_BOOKING';
export const BOOKINGS_SUMMARY_RECEIVED = 'bookings/SUMMARY_RECEIVED';
export const BOOKINGS_SUMMARY_ERROR = 'bookings/SUMMARY_ERROR';
export const BOOKING_CLEAR_SEARCH_RESULTS = 'bookings/CLEAR_SEARCH_RESULTS';

export const ACCOUNTS_LOADING = 'accounts/LOADING';
export const RECEIVE_ACCOUNTS_SEARCH = 'accounts/RECEIVE_SEARCH';
export const ACCOUNTS_SEARCH_ERROR = 'accounts/SEARCH_ERROR';
export const CLEAR_ACCOUNTS_DATA = 'accounts/CLEAR_ACCOUNTS_DATA';

export const SET_GRAPH_LOCATIONS = 'graphs/SET_GRAPH_LOCATIONS';
export const BOOKINGS_GRAPH_DATA_RECEIVED = 'bookings/GRAPH_DATA_RECEIVED';
export const BOOKINGS_GRAPH_DATA_ERROR = 'bookings/GRAPH_DATA_ERROR';

export const UPDATE_ACCOUNT_SUCCESS = 'account/UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'account/UPDATE_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT_SUBMITTING = 'account/SUBMITTING';

export const GET_USER_PROFILE_SUCCESS = 'account/GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'account/GET_USER_PROFILE_ERROR';

export const WAIT_TIME_RECEIVED = 'waitTime/RECEIVED';
export const WAIT_TIME_ERROR = 'waitTime/ERROR';
export const LOADING_WAIT_TIME = 'waitTime/LOADING';

export const WAIT_LIST_RECEIVED = 'waitList/RECEIVED';
export const WAIT_LIST_ERROR = 'waitList/ERROR';
export const WAIT_LIST_LOADING = 'waitList/LOADING';
export const NEXT_ONLINE_APPOINTMENT_RECEIVED = 'waitList/NEXT_ONLINE_APPOINTMENT_RECEIVED';
export const CLEAR_NEXT_ONLINE_APPOINTMENT = 'waitList/CLEAR_NEXT_ONLINE_APPOINTMENT';

export const WAIT_LIST_ORIGIN_SOLV = 'Solv';
export const WAIT_LIST_ORIGIN_WEBSITE = 'Website';
export const WAIT_LIST_ORIGIN_PARTNER_API = 'Partner API';
export const WAIT_LIST_ORIGIN_WALKIN = 'Walk-in';
export const WAIT_LIST_ORIGIN_CALL_AHEAD = 'Call ahead';
export const WAIT_LIST_ORIGIN_BOOK_AHEAD = 'Book ahead';
export const WAIT_LIST_ORIGIN_ONLINE = 'Online';
export const WAIT_LIST_ORIGIN_ASAP = 'Next Available';

export const CLINIC_ACCOUNTS_FETCHING = 'clinicAccounts/FETCHING';
export const CLINIC_ACCOUNTS_RECEIVED = 'clinicAccounts/RECEIVED';
export const CLINIC_ACCOUNTS_ERROR = 'clinicAccounts/ERROR';
export const CLINIC_ACCOUNTS_PATCHED = 'clinicAccounts/PATCHED';
export const CLINIC_ACCOUNTS_PATCH_SUBMITTED = 'clinicAccounts/PATCH_SUBMITTED';
export const CLINIC_ACCOUNTS_PATCH_ERROR = 'clinicAccounts/PATCH_ERROR';
export const CLINIC_ACCOUNTS_RESET_PATCH_STATE = 'clinicAccounts/RESET_PATCH_STATE';
export const CLINIC_ACCOUNTS_ADD_LOCATIONS = 'clinicAccounts/ADD_LOCATIONS';
export const CLINIC_ACCOUNTS_ADD_GROUPS = 'clinicAccounts/ADD_GROUPS';
export const CLINIC_ACCOUNTS_DELETE_GROUPS = 'clinicAccounts/DELETE_GROUPS';
export const CLINIC_ACCOUNTS_DELETE_LOCATIONS = 'clinicAccounts/DELETE_LOCATIONS';
export const CLINIC_ACCOUNTS_PASSWORD_CHANGED = 'clinicAccounts/PASSWORD_CHANGED';
export const CLINIC_ACCOUNTS_LOCATION_PATCHED = 'clinicAccounts/LOCATION_PATCHED';
export const CLINIC_ACCOUNTS_LOCATION_PATCH_ERROR = 'clinicAccounts/LOCATION_PATCH_ERROR';
export const CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_SUCCESS =
  'clinicAccounts/CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_SUCCESS';
export const CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_ERROR =
  'clinicAccounts/CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_ERROR';
export const CLINIC_ACCOUNTS_RESET_ADD_ALL_LOCATIONS =
  'clinicAccounts/CLINIC_ACCOUNTS_RESET_ADD_ALL_LOCATIONS';

export const GROUPS_FETCHING = 'groups/FETCHING';
export const GROUPS_RECEIVED = 'groups/RECEIVED';
export const GROUP_RECEIVED = 'groups/GROUP_RECEIVED';
export const GROUPS_ERROR = 'groups/ERROR';
export const GROUPS_PATCH_SUBMITTED = 'groups/PATCH_SUBMITTED';
export const GROUPS_PATCH_ERROR = 'groups/PATCH_ERROR';
export const GROUPS_PATCH_GROUP = 'groups/PATCH_GROUP';
export const GROUPS_DELETE_LOCATIONS = 'groups/DELETE_LOCATIONS';

export const SET_CURRENT_PATIENT_BOOKING_SUCCESS = 'currentPatient/SET_PATIENT_BOOKING_SUCCESS';
export const SET_CURRENT_PATIENT_BOOKING_ERROR = 'currentPatient/SET_PATIENT_BOOKING_ERROR';
export const GET_CURRENT_PATIENT_USER_PROFILE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_USER_PROFILE_SUCCESS';
export const GET_CURRENT_PATIENT_USER_PROFILE_ERROR =
  'currentPatient/GET_CURRENT_PATIENT_USER_PROFILE_ERROR';
export const GET_CURRENT_PATIENT_INSURANCE_PROFILE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_INSURANCE_PROFILE_SUCCESS';
export const GET_CURRENT_PATIENT_DENTAL_INSURANCE_PROFILE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_DENTAL_INSURANCE_PROFILE_SUCCESS';
export const GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_SUCCESS';
export const GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_ERROR =
  'currentPatient/GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_ERROR';
export const GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_SUCCESS';
export const GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_ERROR =
  'currentPatient/GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_ERROR';
export const GET_CURRENT_PATIENT_DENTAL_INSURANCE_FRONT_CARD_IMAGE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_DENTAL_INSURANCE_FRONT_CARD_IMAGE_SUCCESS';
export const GET_CURRENT_PATIENT_DENTAL_INSURANCE_BACK_CARD_IMAGE_SUCCESS =
  'currentPatient/GET_CURRENT_PATIENT_DENTAL_INSURANCE_BACK_CARD_IMAGE_SUCCESS';

export const GET_CURRENT_PATIENT_INSURANCE_PROFILE_ERROR =
  'currentPatient/GET_CURRENT_PATIENT_INSURANCE_PROFILE_ERROR';
export const RESET_CURRENT_PATIENT = 'currentPatient/RESET_PATIENT';

export const GET_CURRENT_PATIENT_ACCOUNT_SUMMARY = 'currentPatient/ACCOUNT_SUMMARY';
export const GET_CURRENT_PATIENT_ACCOUNT_SUMMARY_ERROR = 'currentPatient/ACCOUNT_SUMMARY_ERROR';
export const GET_CURRENT_PATIENT_BOOKING_DETAILS = 'currentPatient/BOOKING_DETAILS';
export const CURRENT_PATIENT_PAYMENTS_INSURANCE_RECEIVED =
  'currentPatient/PAYMENTS_INSURANCE_RECEIVED';
export const CURRENT_PATIENT_PAYMENTS_INSURANCE_ERROR = 'currentPatient/PAYMENTS_INSURANCE_ERROR';
export const CURRENT_PATIENT_ELIGIBLIITY_REQUEST_ID_RECEIVED =
  'currentPatient/ELIGIBILITY_REQUEST_ID_RECEIVED';
export const CURRENT_PATIENT_INSURANCE_SUMMARY_CLEAR = 'currentPatient/INSURANCE_SUMMARY_CLEAR';
export const CURRENT_PATIENT_RECEIVE_CONSENT_FORM_SIGNATURES =
  'currentPatient/RECEIVE_CONSENT_FORM_SIGNATURES';
export const CURRENT_PATIENT_CONSENT_FORM_SIGNATURES_ERROR =
  'current_patient/CONSENT_FORM_SIGNATURES_ERROR';
export const CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_URL =
  'current_patient/RECEIVE_PHOTO_ID_FRONT_URL';
export const CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_URL =
  'current_patient/RECEIVE_PHOTO_ID_BACK_URL';
export const CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_METADATA =
  'current_patient/RECEIVE_PHOTO_ID_FRONT_METADATA';
export const CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_METADATA =
  'current_patient/RECEIVE_PHOTO_ID_BACK_METADATA';
export const CURRENT_PATIENT_PHOTO_ID_URL_ERROR = 'current_patient/PHOTO_ID_URL_ERROR';

export const RECEIVE_PAPERWORK_RESPONSES = 'paperworkResponses/RECEIVED';
export const RESET_PAPERWORK_RESPONSES = 'paperworkResponses/RESET';
export const PAPERWORK_RESPONSES_ERROR = 'paperworkResponses/ERROR';

export const RECEIVE_BOOKING_HISTORY = 'bookingHistory/RECEIVE';
export const BOOKING_HISTORY_ERROR = 'bookingHistory/ERROR';
export const CLEAR_BOOKING_HISTORY = 'bookingHistory/CLEAR';

export const RECEIVE_PAST_BOOKINGS = 'pastBookings/RECEIVE';
export const PAST_BOOKING_ERROR = 'pastBookings/ERROR';

export const SETTINGS_CHANGE_TAB = 'settings/CHANGE_TAB';
export const SETTINGS_RECEIVE_LOCATIONS = 'settings/RECEIVE_LOCATIONS';
export const SETTINGS_RECEIVE_LOCATIONS_FOR_GROUP_EDIT_FORM =
  'settings/SETTINGS_RECEIVE_LOCATIONS_FOR_GROUP_EDIT_FORM';
export const SETTINGS_RECEIVE_NON_PARTNER_LOCATIONS_FOR_GROUP_EDIT_FORM =
  'settings/SETTINGS_RECEIVE_NON_PARTNER_LOCATIONS_FOR_GROUP_EDIT_FORM';
export const SETTINGS_RECEIVE_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP =
  'settings/RECEIVE_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP';
export const SETTINGS_RECEIVE_TELEMED_LOCATIONS_IN_STATE_BY_GROUP =
  'settings/RECEIVE_TELEMED_LOCATIONS_IN_STATE_BY_GROUP';
export const SETTINGS_FETCHING_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP =
  'settings/FETCHING_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP';
export const SETTINGS_FETCHING_TELEMED_LOCATIONS_IN_STATE_BY_GROUP =
  'settings/FETCHING_TELEMED_LOCATIONS_IN_STATE_BY_GROUP';
export const SETTINGS_ADD_LOCATIONS_GROUPS = 'settings/ADD_LOCATIONS_GROUPS';
export const SETTINGS_FETCHING_LOCATIONS_GROUPS = 'settings/FETCHING_LOCATIONS_GROUPS';
export const SETTINGS_RECEIVE_LOCATIONS_GROUPS = 'settings/RECEIVE_LOCATIONS_GROUPS';
export const SETTINGS_RESET_LOCATIONS_GROUPS = 'settings/RESET_LOCATIONS_GROUPS';
export const SETTINGS_LOCATIONS_ERROR = 'settings/LOCATIONS_ERROR';
export const SETTINGS_SPECIAL_HOURS_SUCCESS = 'settings/SPECIAL_HOURS_SUCCESS';
export const SETTINGS_SPECIAL_HOURS_ERROR = 'settings/SPECIAL_HOURS_ERROR';
export const SETTINGS_RECEIVE_UPCOMING = 'settings/RECEIVE_UPCOMING';
export const SETTINGS_UPCOMING_ERROR = 'settings/UPCOMING_ERROR';
export const SETTINGS_UPDATE_LOCATION_SUCCESS = 'settings/UPDATE_LOCATION_SUCCESS';
export const SETTINGS_UPDATE_LOCATION_ERROR = 'settings/UPDATE_LOCATION_ERROR';
export const SETTINGS_RESERVATION_HOURS_FETCHING = 'settings/FETCHING_RESERVATION_HOURS';
export const SETTINGS_AVAILABILITY_RECOMMENDATIONS_FETCHING =
  'settings/FETCHING_AVAILABILITY_RECOMMENDATIONS';
export const SETTINGS_RESERVATION_HOURS_SUBMITTING = 'settings/SUBMITTING_RESERVATION_HOURS';
export const SETTINGS_RESERVATION_HOURS_SUCCESS = 'settings/RESERVATION_HOURS_SUCCESS';
export const SETTINGS_RESERVATION_HOURS_ERROR = 'settings/RESERVATION_HOURS_ERROR';
export const SETTINGS_RECEIVE_RESERVATION_HOURS = 'settings/RECEIVE_RESERVATION_HOURS';
export const SETTINGS_RECEIVE_RESERVATION_HOURS_ERROR = 'settings/RECEIVE_RESERVATION_HOURS_ERROR';
export const SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS =
  'settings/RECEIVE_AVAILABILITY_RECOMMENDATIONS';
export const SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS_ERROR =
  'settings/RECEIVE_AVAILABILITY_RECOMMENDATIONS_ERROR';
export const SETTINGS_UPDATE_PAYMENTS_ENABLED = 'settings/UPDATE_PAYMENTS_ENABLED';
export const SETTINGS_UPDATE_AUTORESEND_ENABLED = 'settings/UPDATE_AUTORESEND_ENABLED';
export const SETTINGS_UPDATE_POS_ENABLED = 'settings/UPDATE_POS_ENABLED';
export const SETTINGS_POS_ENABLED_SUCCESS = 'settings/POS_ENABLED_SUCCESS';
export const SETTINGS_POS_ENABLED_ERROR = 'settings/POS_ENABLED_ERROR';
export const SETTINGS_PAYMENTS_ENABLED_SUCCESS = 'settings/PAYMENTS_ENABLED_SUCCESS';
export const SETTINGS_PAYMENTS_ENABLED_ERROR = 'settings/PAYMENTS_ENABLED_ERROR';
export const SETTINGS_AUTORESEND_ENABLED_SUCCESS = 'settings/AUTORESEND_ENABLED_SUCCESS';
export const SETTINGS_AUTORESEND_ENABLED_ERROR = 'settings/AUTORESEND_ENABLED_ERROR';
export const SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_SUCCESS =
  'settings/SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_SUCCESS';
export const SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_ERROR =
  'settings/SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_ERROR';
export const SETTINGS_PAYMENTS_DURING_BOOKING_ENABLED =
  'settings/SETTINGS_PAYMENTS_DURING_BOOKING_ENABLED';
export const SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_SUCCESS =
  'settings/SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_SUCCESS';
export const SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_ERROR =
  'settings/SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_ERROR';
export const SETTINGS_PAYMENTS_DURING_BOOKING_REQUIRED =
  'settings/SETTINGS_PAYMENTS_DURING_BOOKING_REQUIRED';
export const SETTINGS_FETCH_SOLVPAY_STATUS = 'settings/FETCH_SOLVPAY_STATUS';
export const SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_SUCCESS =
  'settings/CREATE_NEW_PAYMENT_ACCOUNT_SUCCESS';
export const SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_ERROR =
  'settings/CREATE_NEW_PAYMENT_ACCOUNT_ERROR';
export const SETTINGS_CREATE_NEW_ACCOUNT_FETCH_ERROR = 'settings/CREATE_NEW_ACCOUNT_FETCH_ERROR';
export const SETTINGS_RESET_CREATE_ERROR_STATE = 'settings/RESET_CREATE_ERROR_STATE';
export const SETTINGS_FETCH_SERVICES_LIST_SUCCESS = 'settings/FETCH_SERVICES_LIST_SUCCESS';
export const SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_SUCCESS =
  'settings/PAYMENT_ACCOUNT_LOCATIONS_SUCCESS';
export const SETTINGS_CLEAR_LOCATION_RESULTS = 'settings/CLEAR_LOCATION_RESULTS';
export const SETTINGS_ADDING_LOCATION = 'settings/ADDING_LOCATION';
export const SETTINGS_ADD_LOCATION_SUCCESS = 'settings/ADD_LOCATION_SUCCESS';
export const SETTINGS_ADD_LOCATION_ERROR = 'settings/ADD_LOCATION_ERROR';
export const SETTINGS_CLEAR_ADD_LOCATION_ERROR = 'settings/CLEAR_ADD_LOCATION_ERROR';
export const SETTINGS_EMPLOYEE_SEARCH_RETURNS_EMPTY = 'settings/EMPLOYEE_SEARCH_RETURNS_EMPTY';
export const SETTINGS_EMPLOYEE_SEARCH_RESET = 'settings/EMPLOYEE_SEARCH_RESET';
export const SETTINGS_FETCH_SERVICES_LIST_ERROR = 'settings/FETCH_SERVICES_LIST_ERROR';
export const SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_ERROR = 'settings/PAYMENT_ACCOUNT_LOCATIONS_ERROR';
export const SETTINGS_FETCH_OPERATING_HOURS_SUCCESS = 'settings/FETCH_OPERATING_HOURS_SUCCESS';
export const SETTINGS_FETCH_OPERATING_HOURS_ERROR = 'settings/FETCH_OPERATING_HOURS_ERROR';
export const SETTINGS_PATCH_OPERATING_HOURS_SUCCESS = 'settings/PATCH_OPERATING_HOURS_SUCCESS';
export const SETTINGS_PATCH_OPERATING_HOURS_ERROR = 'settings/PATCH_OPERATING_HOURS_ERROR';
export const SETTINGS_SUBMITTING = 'settings/SUBMITTING';
export const SETTINGS_POST_LOCATION_PAPERWORK_FIELD_SUCCESS =
  'settings/POST_LOCATION_PAPERWORK_FIELD_SUCCESS';
export const SETTINGS_POST_LOCATION_PAPERWORK_FIELD_ERROR =
  'settings/POST_LOCATION_PAPERWORK_FIELD_ERROR';

export const SETTINGS_RECEIVE_LAB_TEST_CONFIG = 'settings/SETTINGS_RECEIVE_LAB_TEST_CONFIG';
export const SETTINGS_RECEIVE_LAB_TEST_CONFIG_DEFAULT = 'settings/RECEIVE_LAB_TEST_CONFIG_DEFAULT';
export const SETTINGS_RECEIVE_GROUPS = 'settings/RECEIVE_GROUPS';
export const SETTINGS_GROUPS_ERROR = 'settings/GROUPS_ERROR';
export const SETTINGS_GROUPS_LOADING = 'settings/GROUPS_LOADING';
export const SETTINGS_RECEIVE_PRACTICES = 'settings/RECEIVE_PRACTICES';
export const SETTINGS_RECEIVE_PRACTICE = 'settings/RECEIVE_PRACTICE';
export const SETTINGS_SET_SELECTED_PRACTICE_ID = 'settings/SET_SELECTED_PRACTICE_ID';
export const SETTINGS_SET_SEARCH_FILTERS = 'settings/SETTINGS_SET_SEARCH_FILTERS';
export const SETTINGS_PROVIDER_GROUP_LOCATIONS_LOADING =
  'settings/PROVIDER_GROUP_LOCATIONS_LOADING';
export const SETTINGS_PROVIDER_GROUP_LOCATIONS_ERROR = 'settings/PROVIDER_GROUP_LOCATIONS_ERROR';
export const SETTINGS_PRACTICES_ERROR = 'settings/PRACTICES_ERROR';
export const SETTINGS_PRACTICES_LOADING = 'settings/PRACTICES_LOADING';
export const SETTINGS_RECEIVE_PROVIDER_GROUP_LOCATIONS =
  'settings/SETTINGS_RECEIVE_PROVIDER_GROUP_LOCATIONS';

// Pulse
export const PULSES_RECEIVE_DATA = 'pulses/RECEIVE_DATA';
export const PULSES_RECEIVE_DATA_ERROR = 'pulses/RECEIVE_DATA_ERROR';
export const PULSES_CLEAR_ERRORS = 'pulses/CLEAR_ERRORS';
export const PULSES_PATCH_DATA = 'pulses/PATCHING_DATA';
export const PULSE_SET_LOCATION = 'pulses/PULSE_SET_LOCATION';

// Clinic Account Activation
export const ACTIVATION_CLEAR_ERRORS = 'activation/CLEAR_ERRORS';
export const ACTIVATION_CREATE_ERROR = 'activation/CREATE_ERROR';
export const ACTIVATION_CREATE_SUCCESS = 'activation/CREATE_SUCCESS';
export const ACTIVATION_SET_CLINIC_ACCOUNT = 'activation/SET_CLINIC_ACCOUNT';
export const ACTIVATION_SET_ENCRYPTED_VALUE = 'activation/SET_ENCRYPTED_VALUE';
export const ACTIVATION_SET_LAST_NAME = 'activation/SET_LAST_NAME';
export const ACTIVATION_SET_NONCE = 'activation/SET_NONCE';
export const ACTIVATION_VERIFY_ERROR = 'activation/VERIFY_ERROR';

export const INVOICE_STATUS_PENDING = 'pending';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_POSTED = 'posted';
export const INVOICE_STATUS_CANCELED = 'cancelled';

export const INVOICE_TYPE_SMS = 'bill';
export const INVOICE_TYPE_WEBSITE = 'website';
export const INVOICE_TYPE_POS = 'pos';

export const CHARGE_STATUS_SUCCEEDED = 'succeeded';

export const CHARGES_RECEIVED = 'charges/RECEIVED';
export const CHARGES_ERROR = 'charges/ERROR';
export const CHARGES_SUBMITTING = 'charges/SUBMITTING';
export const CHARGES_TOKEN_ERROR = 'charges/TOKEN_ERROR';

export const CLINIC_RESET_PASSWORD = 'clinic/RESET_PASSWORD';

// other stuff
export const QUEUE_TAB_ACTIVE = 'active';
export const QUEUE_TAB_COMPLETED = 'completed';
export const QUEUE_TAB_RESERVED = 'reserved';

export enum QueueTab {
  Active = 'active',
  Completed = 'completed',
  Reserved = 'reserved',
  // Used primarily on query bookings for patient search, to not filter by status
  All = 'all',
}

// settings tabs
export const SETTINGS_TAB_GROUPS = 'stg';
export const SETTINGS_TAB_LOCATIONS = 'stl';

export const SETTINGS_TAB_SCHEDULING = 'sts';
export const SETTINGS_TAB_PROVIDERS = 'stp';
export const SETTINGS_TAB_PRACTICES = 'stpr';
export const SETTINGS_TAB_USERS = 'stu';
export const SETTINGS_TAB_CUSTOM_QUESTIONS = 'stcq';

export const SETTINGS_TAB_SFTP_INTEGRATIONS = 'stsftp';

export const EDIT_LOCATION_SPECIAL_HOURS = 'elsh';
export const EDIT_LOCATION_RESERVATION_HOURS = 'elrh';
export const CAPACITY = 'c';

// Admin queue tabs
export const ADMIN_PATIENTS_ACCOUNTS = 'accounts';
export const ADMIN_PATIENTS_BOOKINGS = 'bookings';
export const ADMIN_PATIENTS_RESULTS = 'lab-results';
export const ADMIN_BOOKINGS_ASSIGN = 'assign';

export const RESOURCE_TYPE_ACCOUNTS = ADMIN_PATIENTS_ACCOUNTS;
export const RESOURCE_TYPE_BOOKINGS = ADMIN_PATIENTS_BOOKINGS;

// Call center queue tabs
export const CALL_CENTER_MAKE = 'make';
export const CALL_CENTER_EDIT = 'edit';

// Queue tools
export const TOOLS_TAB_INSURANCE = 'insurance beta';
export const TOOLS_TAB_HISTORY = 'history';
export const TOOLS_TAB_FAQ = 'faq';

export const QUEUE_LIST_TYPE_RESERVED = 'reserved';
export const QUEUE_LIST_TYPE_ARRIVING_SOON = 'arriving soon';
export const QUEUE_LIST_TYPE_HERE_NOW = 'here now';
export const QUEUE_LIST_TYPE_IN_EXAM_ROOM = 'in exam room';
export const QUEUE_LIST_TYPE_COMPLETED = 'completed';
export const QUEUE_LIST_TYPE_READY = 'ready';

export enum QueueListType {
  Reserved = 'reserved',
  ArrivingSoon = 'arriving soon',
  Here = 'here now',
  InExam = 'in exam room',
  Completed = 'completed',
  Ready = 'ready',
  Edit = 'edit',
}

export const STALE_REVIEWS_DATA = 'reviews/STALE_REVIEWS_DATA';
export const REVIEWS_DATA_RECEIVED = 'reviews/DATA_RECEIVED';
export const REVIEWS_NEW_REVIEWS_RECEIVED = 'reviews/REVIEWS_NEW_REVIEWS_RECEIVED';
export const REVIEWS_DATA_ERROR = 'reviews/DATA_FETCHING_ERROR';
export const RESET_REVIEW_COUNTER = 'reviews/RESET_REVIEW_COUNTER';

export const MESSAGES_DATA_RECEIVED = 'messages/DATA_RECEIVED';
export const MESSAGES_NEW_MESSAGE_RECEIEVED = 'messages/MESSAGES_NEW_MESSAGE_RECEIEVED';
export const MESSAGES_ERROR = 'messages/ERROR';
export const RESET_UNREAD_MESSAGES_COUNTER = 'messages/RESET_UNREAD_MESSAGES_COUNTER';

export const BOOKING_STATUS_PENDING = 'pending';
export const BOOKING_STATUS_RESERVED = 'reserved';
export const BOOKING_STATUS_REQUESTED = 'requested';
export const BOOKING_STATUS_CONFIRMED = 'confirmed';
export const BOOKING_STATUS_CHECKED_IN = 'checked_in';
export const BOOKING_STATUS_IN_EXAM_ROOM = 'in_exam_room';
export const BOOKING_STATUS_DISCHARGED = 'discharged';
export const BOOKING_STATUS_CANCELLED = 'cancelled';
export const BOOKING_STATUS_NO_SHOW = 'no_show';
export const BOOKING_STATUS_REJECTED = 'rejected';

export enum BookingStatus {
  Pending = 'pending',
  Reserved = 'reserved',
  Requested = 'requested',
  Confirmed = 'confirmed',
  CheckedIn = 'checked_in',
  InExam = 'in_exam_room',
  Discharged = 'discharged',
  Cancelled = 'cancelled',
  NoShow = 'no_show',
  Rejected = 'rejected',
}

export const CANCELLATION_REASON_TRANSFERRED = 'transferred';

export const SORT_ORDER_ASCENDING = 'asc';
export const SORT_ORDER_DESCENDING = 'desc';

export enum PackageName {
  SOLV_EXPRESS = 'solv_express',
  PROFESSIONAL = 'professional',
  FOUNDATION = 'foundation',
  ADVANCED = 'advanced',
  ENTERPRISE = 'enterprise',
  OFFBOARDED = 'offboarded',
  OFFBOARDED_VIEWABLE = 'offboarded_viewable',
}

export enum AccountRole {
  ACCOUNT_ROLE_FRONT_OFFICE = 'front_office',
  ACCOUNT_ROLE_BACK_OFFICE = 'back_office',
  ACCOUNT_ROLE_IT_ADMIN = 'it_admin',
  ACCOUNT_ROLE_MANAGER = 'manager',
  ACCOUNT_ROLE_PROVIDER = 'provider',
  ACCOUNT_ROLE_BILLING = 'billing',
  ACCOUNT_ROLE_SOLV_EMPLOYEE = 'solv_employee',
  ACCOUNT_ROLE_ADMIN = 'admin',
  ACCOUNT_ROLE_CALL_CENTER = 'call_center',
  ACCOUNT_ROLE_CALL_CENTER_MANAGER = 'call_center_manager',
}

export const ACCOUNT_ROLE_FRONT_OFFICE = AccountRole.ACCOUNT_ROLE_FRONT_OFFICE;
export const ACCOUNT_ROLE_BACK_OFFICE = AccountRole.ACCOUNT_ROLE_BACK_OFFICE;
export const ACCOUNT_ROLE_IT_ADMIN = AccountRole.ACCOUNT_ROLE_IT_ADMIN;
export const ACCOUNT_ROLE_MANAGER = AccountRole.ACCOUNT_ROLE_MANAGER;
export const ACCOUNT_ROLE_PROVIDER = AccountRole.ACCOUNT_ROLE_PROVIDER;
export const ACCOUNT_ROLE_BILLING = AccountRole.ACCOUNT_ROLE_BILLING;
export const ACCOUNT_ROLE_SOLV_EMPLOYEE = AccountRole.ACCOUNT_ROLE_SOLV_EMPLOYEE;
export const ACCOUNT_ROLE_ADMIN = AccountRole.ACCOUNT_ROLE_ADMIN;

// Partner-specific role types
export const ACCOUNT_ROLE_CALL_CENTER = AccountRole.ACCOUNT_ROLE_CALL_CENTER;
export const ACCOUNT_ROLE_CALL_CENTER_MANAGER = AccountRole.ACCOUNT_ROLE_CALL_CENTER_MANAGER;

export const ACCOUNT_ROLE_DESCRIPTIONS = {
  [ACCOUNT_ROLE_FRONT_OFFICE]: [
    'Can manage bookings',
    'Can download the patient report',
    'Can manage invoices if assigned to payments-enabled locations',
  ],
  [ACCOUNT_ROLE_BACK_OFFICE]: [
    'Goes directly to Ready tab upon navigating to a queue',
    'Can manage bookings',
    'Can download the patient report',
    'Can manage invoices if assigned to payments-enabled locations',
  ],
  [ACCOUNT_ROLE_IT_ADMIN]: [
    'Can manage accounts in clinic group',
    'Can manage locations in clinic group',
  ],
  [ACCOUNT_ROLE_PROVIDER]: [
    'Can manage bookings',
    'Can view all reports and download patient and clinic reports',
    'Can manage invoices if assigned to payments-enabled locations',
  ],
  [ACCOUNT_ROLE_MANAGER]: [
    'Can manage bookings',
    'Can view all reports and download patient and clinic reports',
    'Can manage invoices if assigned to payments-enabled locations',
    'Can manage accounts in clinic group',
    'Can manage locations in clinic group',
  ],
  [ACCOUNT_ROLE_BILLING]: ['Can manage invoices if assigned to payments-enabled locations'],
  [ACCOUNT_ROLE_SOLV_EMPLOYEE]: ['Can do everything across all groups/locations/accounts'],
  [ACCOUNT_ROLE_ADMIN]: [
    'Can do everything across all groups/locations/accounts',
    'Can create Solv Employees',
  ],
  [ACCOUNT_ROLE_CALL_CENTER]: ['Can manage bookings'],
  [ACCOUNT_ROLE_CALL_CENTER_MANAGER]: [
    'Can manage bookings',
    'Can view all reports and download patient and clinic reports',
    'Can manage invoices if assigned to payments-enabled locations',
    'Can manage accounts in clinic group',
    'Can manage locations in clinic group',
    'Can assign Call Center role',
  ],
};

export const ACCOUNT_ROLES = [
  ACCOUNT_ROLE_FRONT_OFFICE,
  ACCOUNT_ROLE_BACK_OFFICE,
  ACCOUNT_ROLE_CALL_CENTER,
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_CALL_CENTER_MANAGER,
  ACCOUNT_ROLE_PROVIDER,
  ACCOUNT_ROLE_BILLING,
  ACCOUNT_ROLE_IT_ADMIN,
  ACCOUNT_ROLE_SOLV_EMPLOYEE,
  ACCOUNT_ROLE_ADMIN,
];

export const ACCOUNT_STATUS_ACTIVE = 'Active';
export const ACCOUNT_STATUS_DEACTIVATED = 'Deactivated';

export const ENV_DEV = 'dev';
export const ENV_DEV_NODE = 'development';
export const ENV_STAGE = 'stage';
export const ENV_PROD = 'prod';
export const ENV_SECURE_PROD = 'secure-prod';
export const ENV_PROD_NODE = 'production';
export const ENV_TEST = 'test';
export const ENV_LIVESTAGE = 'livestage';

export const VALID_SOLV_ENVS = [
  ENV_DEV,
  ENV_TEST,
  ENV_STAGE,
  ENV_LIVESTAGE,
  ENV_PROD,
  ENV_SECURE_PROD,
];

export const TYPE_HMO = 'hmo';
export const TYPE_PPO = 'ppo';
export const TYPE_UNKNOWN = 'unknown';
export const TYPE_SELF = 'self-pay';
export const TYPE_SELF_LABEL = 'Self-Pay';
export const SELF_PAY_ONLY = 'self_pay_only';
export const TYPE_MEDICARE = 'medicare';
export const TYPE_MEDICAID = 'medicaid';
export const TYPE_TRICARE = 'tricare';
export const TYPE_TRIWEST = 'triwest';
export const TYPE_OTHER = 'other';
export const TYPE_SKIP = 'skip';
export const TYPE_SELF_PAY = 'self_pay';
export const TYPE_AETNA = 'aetna';
export const TYPE_BLUE_CROSS_BLUE_SHIELD = 'blue_cross_blue_shield';
export const TYPE_CIGNA = 'cigna';
export const TYPE_UNITED_HEALTHCARE = 'united_healthcare';
export const TYPE_ANTHEM = 'anthem';
export const TYPE_HUMANA = 'humana';

export const SMS_CONSENT_ALLOW = 'allow';
export const SMS_CONSENT_DENY = 'deny';

export const CALL_AHEAD = 'callAhead';
export const WALK_IN = 'walkIn';

export const EXISTING_PATIENT = 'callAhead';
export const NEW_PATIENT = 'walkIn';

export const ADD_NEW_PATIENT = 'New';
export const ESTABLISHED_PATIENT = 'Established';
export const PATIENT_TYPE = 'Patient Type';

export const ORIGIN_BOOKING_FORM = 'booking_form';
export const ORIGIN_BOOKING_WIDGET = 'booking_widget';
export const ORIGIN_QUEUE_RESERVED_ONLY = 'queue_reserved_only';
export const ORIGIN_PARTNER_API = 'partner_api';
export const ORIGIN_QUEUE = 'queue';
export const ORIGIN_QUEUE_WALK_IN = 'queue_walk_in';
export const ORIGIN_MOBILE_APP = 'react_mobile_app';
export const ORIGIN_KIOSK_SIGN_IN = 'kiosk_sign_in';
export const ORIGIN_CALL_AHEAD = 'call_ahead';
export const ORIGIN_WALK_IN_QUEUE = 'queue_walk_in';
export const ORIGIN_EXTERNAL_SCHEDULE_SYNC = 'external_schedule_sync';

export const AUTHOR_TYPE_CLINIC_ACCOUNT = 'clinic_account';

export const SERVICE_TYPE = 'health_benefit_plan_coverage';
export const URGENT_CARE_DEFAULT_SERVICE_CODE = 'UC';

export const BOOKING_IS_EXISTING_PATIENT = true;
export const BOOKING_IS_NOT_EXISTING_PATIENT = false;

export const BOOKING_ADD_FIELDS = 'newBooking/ADD_FIELDS';
export const BOOKING_ADD_PENDING_FIELDS = 'newBooking/ADD_PENDING_FIELDS';
export const BOOKING_CLEAR_PENDING_FIELDS = 'newBooking/CLEAR_PENDING_FIELDS';
export const CLEAR_BOOKING = 'newBookings/CLEAR_BOOKING';

export const GO_TO_NEXT_SUCCESS_SLIDE_TIMEOUT = 4500;

export const REPORT_REQUEST_RECEIVED_OLD = 'report/REQUEST_REPORT_RECEIVED_OLD';
export const REPORT_REQUEST_REPORT_ERROR_OLD = 'report/REQUEST_REPORT_ERROR_OLD';
export const REPORT_ERROR = 'reports/REPORT_ERROR';
export const SET_REPORT_DATES = 'reports/SET_DATES';
export const SET_REPORT_LOCATIONS = 'reports/SET_LOCATIONS';
export const SET_PERFORMANCE_PRICING_LOCATIONS_INFO =
  'reports/SET_PERFORMANCE_PRICING_LOCATION_INFO';
export const SET_EARLIEST_LIVE_DATE = 'reports/SET_EARLIEST_LIVE_DATE';
export const RESET_REPORTS = 'reports/RESET_REPORTS';
export const RECEIVE_REVIEWS_SUMMARY = 'reports/RECEIVE_REVIEWS_SUMMARY';
export const REVIEWS_SUMMARY_ERROR = 'reports/REVIEWS_SUMMARY_ERROR';
export const RECEIVE_REVIEWS = 'reports/RECEIVE_REVIEWS';
export const RECEIVE_REVIEW = 'reports/RECEIVE_REVIEW';
export const RESET_REVIEWS_CURENT_PAGE = 'reports/RESET_REVIEWS_CURENT_PAGE';
export const REVIEWS_ERROR = 'reports/REVIEWS_ERROR';
export const RESET_REVIEWS = 'reports/RESET_REVIEWS';
export const UPDATE_FILTERS = 'reports/UPDATE_FILTERS';
export const PAPERLESS_REPORTS_GRAPH_DATA_RECEIVED =
  'reports/PAPERLESS_REPORTS_GRAPH_DATA_RECEIVED';
export const TELEMED_REPORTS_GRAPH_DATA_RECEIVED = 'reports/TELEMED_REPORTS_GRAPH_DATA_RECEIVED';
export const PAPERLESS_REPORTS_GRAPH_DATA_ERROR = 'reports/PAPERLESS_REPORTS_GRAPH_DATA_ERROR';
export const TELEMED_REPORTS_GRAPH_DATA_ERROR = 'reports/TELEMED_REPORTS_GRAPH_DATA_ERROR';

export const SET_DOWNLOAD_REPORT_FILE_FORMAT = 'reports/SET_DOWNLOAD_REPORT_FILE_FORMAT';

export const SET_REVIEWS_MODERATION_TAB = 'reviewsModeration/SET_TAB';
export const REVIEWS_LIST_RECEIVED = 'reviewsModeration/REVIEWS_LIST_RECEIVED';
export const REVIEW_RECEIVED = 'reviewsModeration/REVIEW_RECEIVED';
export const REVIEW_UPDATED_IN_SEARCH_RESULTS =
  'reviewsModeration/REVIEW_UPDATED_IN_SEARCH_RESULTS';

export const REVIEWS_CLEAR = 'reviewsModeration/REVIEWS_CLEAR';
export const REVIEWS_TO_MODERATE_ERROR = 'reviewsModeration/REVIEWS_ERROR';
export const LANG_ENG = 'en';
export const LANG_SPA = 'es';
export const DEFAULT_LANGS = [LANG_ENG, LANG_SPA] as const;
export const LANG_CHANGE = 'language/CHANGE';

export const LOCATION_TYPE_PARTNER = 'partner';
export const LOCATION_TYPE_BOOKABLE = 'bookable';
export const LOCATION_TYPE_OTHER = 'other';

export const PLATFORM_TYPE_URGENT_CARE = 'urgent_care';
export const PLATFORM_TYPE_PROVIDER_GROUP = 'provider_group';

export const SOLV_PLUS_STATUS_TRADITIONAL = 'traditional_only';
export const SOLV_PLUS_STATUS_PREMIUM = 'premium_only';
export const SOLV_PLUS_STATUS_BOTH = 'both';

export const GROUP_TYPE_BRAND = 'brand';

export const METERS_PER_MILE = 1609;

export const FACESHEET_TAB_PROFILE = 'profile';
export const FACESHEET_TAB_VISITS = 'visits';
export const FACESHEET_TAB_INSURANCE_DETAILS = 'insurance_details';
export const FACESHEET_TAB_BOOKING_DETAILS = 'details';
export const FACESHEET_TAB_PAPERWORK = 'ftp';
export const FACESHEET_TAB_CONSENT_FORMS = 'ftcf';
export const FACESHEET_TAB_LAB_RESULTS = 'ftclr';
export const FACESHEET_TAB_BOOKING_HISTORY = 'ftbh';
export const FACESHEET_TAB_PAYMENTS = 'ftpay';
export const FACESHEET_TAB_PHOTO_ID = 'ftpi';
export const FACESHEET_TAB_VACCINATIONS = 'ftv';
export const FACESHEET_TAB_VISIT_SUMMARY = 'ftvs';
export const FACESHEET_TAB_EMR_TRANSFER = 'emrt';
export const FACESHEET_TAB_RTE = 'rte';

export const LOOK_UP_TYPE_PHONE = 'phone';

export const REGISTRATION_PAPERWORK = 'registration';
export const PAPERWORK_COMPLETED = 'complete';
export const PAPERWORK_IN_PROGRESS = 'in_progress';
export const PAPERWORK_INCOMPLETE = 'incomplete';

export const EMPTY_STRING = '';
export const EMPTY_OBJECT = {};

export const STRING_TRUE = 'true';
export const STRING_FALSE = 'false';

export const PATIENT_TYPE_EXISTING = 'existing';

export const PAPERWORK_RESPONSE_TYPE_REGISTRATION = 'registration';

export const PATIENT_BOOST_REPORT_TAB = 'Patient Boost';
export const DOWNLOAD_REPORT_TAB = 'Download Report';
export const REVIEWS_REPORT_TAB = 'Reviews';
export const PAPERLESS_REPORT_TAB = 'Paperless';
export const TELEMED_REPORT_TAB = 'Video Telemed';
export const HISTORICAL_DASHBOARD_REPORT_TAB = 'Patient Experience';
export const LIVE_DASHBOARD_REPORT_TAB = 'Live Stats';
export const ADDRESS_VALIDATION_REPORT_TAB = 'Address Validation';
export const BRANDED_SCHEDULER_REPORT_TAB = 'Website Scheduler';
export const CAPACITY_INSIGHTS_TAB = 'Capacity Insights';
export const OPERATIONS_BENCHMARK_TAB = 'Operations Benchmark';

export const PATIENT_BOOST_REPORT_TAB_PATH = '/reports/patient-boost';
export const DOWNLOAD_REPORT_TAB_PATH = '/reports/download-reports';
export const REVIEWS_REPORT_TAB_PATH = '/reports/reviews';
export const PAPERLESS_REPORT_TAB_PATH = '/reports/paperless';
export const TELEMED_REPORT_TAB_PATH = '/reports/telemed';
export const HISTORICAL_DASHBOARD_REPORT_TAB_PATH = '/reports/patient-experience';
export const LIVE_DASHBOARD_REPORT_TAB_PATH = '/reports/live-stats';
export const ADDRESS_VALIDATION_REPORT_TAB_PATH = '/reports/address-validation';
export const BRANDED_SCHEDULER_REPORT_TAB_PATH = '/reports/branded-scheduler';
export const SET_PROVIDERS = '/reports/providers';
export const CAPACITY_ANALYTICS_TAB_PATH = '/reports/capacity-insights';
export const OPERATIONS_BENCHMARK_TAB_PATH = '/reports/operations-benchmark';

export const CONNECT_ANALYSIS_TAB_PATH = '/connect/analysis';

// we need different paths for employees who can access both the enterprise and smb reports
// but we want non-employees to just see snapshot as the path.
export const CONNECT_SNAPSHOT_TAB_PATH = '/connect/snapshot';
export const SOLV_EMPLOYEE_ENTERPRISE_SNAPSHOT_TAB_PATH = '/connect/ent-snapshot';
export const SOLV_EMPLOYEE_SMB_SNAPSHOT_TAB_PATH = '/connect/smb-snapshot';

export const MODERATE_REVIEWS_MODERATION_TAB = 'Moderate';
export const VIEW_ALL_REVIEWS_MODERATION_TAB = 'Search';

export const DEFAULT_TABLE_LOCALE = {
  filterTitle: 'Filter',
  filterConfirm: 'Confirm',
  filterReset: 'Reset',
  emptyText: 'No Results',
};

export const TELEMED_IN_PROGRESS = 'in_progress';
export const TELEMED_DONE = 'done';

export const UBER_RIDE_STATUS_REQUESTED = 'requested';
export const UBER_RIDE_STATUS_SCHEDULED = 'scheduled';

export const HIPAA_WARNING =
  "Please enter the patient's real phone number. A wrong phone number can lead to HIPAA violations.";

export const DAILY_REVIEW_EMAIL_ENABLED = 'daily_review_email_enabled';
export const WEEKLY_REVIEW_EMAIL_ENABLED = 'weekly_review_email_enabled';
export const PAYMENTS_EMAIL_ENABLED = 'payments_email_enabled';
export const LOW_FEEDBACK_NOTIFICATIONS = 'low_feedback_notifications';
export const TELEMED_BOOKING_EMAIL_NOTIFICATIONS = 'telemed_email_enabled';
export const TELEMED_BOOKING_SMS_NOTIFICATIONS = 'telemed_sms_enabled';
export const PHYSICAL_BOOKING_EMAIL_NOTIFICATION = 'physical_location_email_notification_enabled';
export const PATIENT_BOOST_EMAIL = 'patient_boost_email_enabled';
export const PG_BOOKING_EMAIL_ENABLED = 'pg_booking_email_enabled';
export const TELEMED_WEEKLY_REPORT_EMAIL = 'weekly_telemed_report_email_enabled';

export const EMAILED_CLINIC_NOTIFICATION_TYPES = [
  DAILY_REVIEW_EMAIL_ENABLED,
  WEEKLY_REVIEW_EMAIL_ENABLED,
  PAYMENTS_EMAIL_ENABLED,
  LOW_FEEDBACK_NOTIFICATIONS,
  TELEMED_BOOKING_EMAIL_NOTIFICATIONS,
  PATIENT_BOOST_EMAIL,
  TELEMED_WEEKLY_REPORT_EMAIL,
  PHYSICAL_BOOKING_EMAIL_NOTIFICATION,
  PG_BOOKING_EMAIL_ENABLED,
];

export const SMS_CLINIC_NOTIFICATION_TYPES = [TELEMED_BOOKING_SMS_NOTIFICATIONS];

export const PERMISSION_CLINIC_ACCOUNT_LAB_RESULTS = 'lab_results_access_enabled';
export const PERMISSION_CLINIC_ACCOUNT_CONNECT = 'connect_access_enabled';
export const PERMISSION_CLINIC_ACCOUNT_MESSAGING = 'messaging_access_enabled';
export const PERMISSION_PROVIDER_REPORTING = 'provider_reporting_enabled';

export const PERMISSION_CLINIC_ACCOUNT_REPLY_TO_REVIEWS = 'reply_to_reviews_enabled';

export const PERMISSION_CLINIC_ACCOUNT_REVIEWS_CREATE_RESPONSE_TEMPLATES =
  'create_response_templates_enabled';

export const PERMISSIONS_CLINIC_ACCOUNT = [
  PERMISSION_CLINIC_ACCOUNT_LAB_RESULTS,
  PERMISSION_CLINIC_ACCOUNT_CONNECT,
  PERMISSION_CLINIC_ACCOUNT_REPLY_TO_REVIEWS,
  PERMISSION_CLINIC_ACCOUNT_REVIEWS_CREATE_RESPONSE_TEMPLATES,
  PERMISSION_CLINIC_ACCOUNT_MESSAGING,
  PERMISSION_PROVIDER_REPORTING,
] as const;

export const PRE_CSS_LOADER_STYLE = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100vh',
};

export const HAS_DENIED_IN_LAST_30_DAYS_COOKIE_NAME = 'NotificationsOptIn - Should Hide';
export const HAS_DISMISSED_CLEAR_PRICES_PARTNER_PROMPT_COOKIE_NAME =
  'ClearPricesOptIn - Should Hide';

export const PASSWORD_RE_PATTERN =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*'."-])[a-zA-Z0-9#?!@$%^&*--]{8,256}$/;
export const PASSWORD_VALIDATION_ERROR_MESSAGE =
  'Password must be 8 to 256 characters long, and include ' +
  'one lower-case letter, one upper-case letter, one number, ' +
  'and one of the following special characters: #?!@$%^&*-';

export const CLINIC_ACCOUNT_EMAIL_ACTIVATION = 'CLINIC_ACCOUNT_ACTIVATION';

// invoice
export const INVOICES_RECEIVED = 'invoice/RECEIVED';
export const INVOICES_ERROR = 'invoice/ERROR';

// messages
export const CANNED_MESSAGE_COVID_TRIAGE = 'covidTriage';
export const CANNED_MESSAGE_FEEDBACK = 'feedback';
export const CANNED_MESSAGE_FOLLOW_UP = 'followup';
export const CANNED_MESSAGE_NEXTCARE_SIGN_UP = 'nextCareSignUp';
export const CANNED_MESSAGE_PAPERWORK = 'paperwork';
export const CANNED_MESSAGE_RESEND_VIDEO_LINK = 'resendVideoLink';
export const CANNED_MESSAGE_WAITING_ROOM_READY = 'waitingRoomReady';
export const CANNED_MESSAGE_WAITLIST = 'waitlist';
export const CANNED_MESSAGE_URGENT_CARE_FOR_KIDS = 'ucfk_vehicle';
export const CANNED_MESSAGE_MULTI_CARE_INDIO_PAPERWORK = 'multicareIndigoPaperwork';
export const CUSTOM_CANNED_MESSAGE_PREFIX = 'customCannedMessage:'; // The colon (:) is important in distinguishing custom from std quick replies
export const CONVERSATIONS_MODAL_ACTION_BUTTON = 'actionButton';
export const CONVERSATIONS_MODAL_BOOKING_ROW = 'bookingRow';

export const REFERRAL_MESSAGE_UC = 'refer_uc';
export const REFERRAL_MESSAGE_COVID_TEST = 'refer_covid_test';
export const REFERRAL_MESSAGE_TELEMED_FOLLOWUP = 'refer_video_followup';

export const PREFERRED_TIME_ASAP = 'asap';
export const ASAP_LABEL = 'Next Available';

// edit payment options
export const ENABLE_POS_IN_LOCATIONS_SETTINGS = 'payments/enablePosInLocationSettings';
export const ENABLE_AUTORESEND_IN_LOCATIONS_SETTINGS =
  'payments/enableAutoResendInLocationSettings';
export const ENABLE_PAYMENTS_IN_LOCATIONS_SETTINGS = 'payments/enablePaymentsInLocationSettings';
export const ENABLE_PAYMENTS_DURING_BOOKING_IN_LOCATIONS_SETTINGS =
  'payments/enablePaymentsDuringBookingInLocationSettings';
export const REQUIRE_PAYMENTS_DURING_BOOKING_IN_LOCATIONS_SETTINGS =
  'payments/requirePaymentsDuringBookingInLocationSettings';
export const FETCH_CLINIC_ACCOUNTS = 'payments/fetchClinicAccounts';
export const CREATE_PAYMENT_ACCOUNT = 'payments/createPaymentAccount';
export const RESET_CREATE_PAYMENT_ACCOUNT = 'payments/resetCreatePaymentAccountErrors';

export const ABBREVIATED_WEEKDAY_WITH_DATE = 'ddd, mm/dd/yy';

export const STATE_ABBREVIATIONS = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

// Reset password
export const PASSWORD_RESET_SUBMITTED = 'PASSWORD_RESET_SUBMITTED';
export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED';
export const PASSWORD_RESET_CLEAR_ERRORS = 'PASSWORD_RESET_CLEAR_ERRORS';

export const VERSION_ONE = 'v1';
export const VERSION_TWO = 'v2';
export const VERSION_FOUR = 'v4';

export const BOOKINGS_ATTRIBUTION_TYPE_SOLV = 'solv';
export const BOOKINGS_ATTRIBUTION_TYPE_PROFILE_OPTIMIZATION = 'profile';
export const BOOKINGS_ATTRIBUTION_TYPE_CLINIC = 'clinic';
export const BOOKINGS_ATTRIBUTION_TYPE_CLINIC_WALKIN = 'clinic_walkin';

export const BOOKINGS_ATTRIBUTION_TYPE_SOLV_BADGE_TITLE = 'Solv App';

export const DEFAULT_REVENUE_PER_VISIT = 150;

export const REPORTS_LOCATION_QUERY_LIMIT = 1000;

export const BIRTH_SEX_MALE = 'male';
export const BIRTH_SEX_FEMALE = 'female';

export const REVIEW_FLOW_TYPE_WEB_ASK_RECOMMEND_AND_DIRECT_TO_GOOGLE =
  'WEB_ASK_RECOMMEND_AND_DIRECT_TO_GOOGLE';
export const REVIEW_FLOW_TYPE_ADD_REVIEW_DIRECT_TO_SOLV = 'WEB_DIRECT_TO_SOLV';
export const REVIEW_FLOW_TYPE_UNGATED_REVIEW_ON_GOOGLE_OR_SOLV = 'UNGATED_REVIEW_ON_GOOGLE_OR_SOLV';
export const REVIEW_FLOW_TYPE_UNGATED = 'UNGATED';
export const REVIEW_FLOW_TYPE_REVIEW_FLOW_DISABLED = 'REVIEW_FLOW_DISABLED';
export const REVIEW_FLOW_TYPE_NPS_AND_WEB_ASK_RECOMMEND_AND_DIRECT_TO_GOOGLE =
  'NPS_AND_WEB_ASK_RECOMMEND_AND_DIRECT_TO_GOOGLE';

export const GRAPH_TYPE_VISIT_LENGTH = 'visitLength';
export const GRAPH_TYPE_AVG_VISIT_LENGTH = 'avgVisitLength';
export const GRAPH_TYPE_PATIENT_VOLUME = 'patientVolume';
export const VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_LAST_MONTH = 'lastMonth'; // unlike the other graph types, this one doesn't correspond to a graphType used in dapi – this is solely used for collecting patientVolume data for the last month for a new graph
export const VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_ALL_TIME = 'allTime';
export const GRAPH_TYPE_PATIENT_SATISFACTION = 'patientSatisfaction';
export const GRAPH_TYPE_FAKE_PHONE_NUMBERS = 'fakePhoneNumbers';
export const GRAPH_TYPE_PAPERLESS_REGISTRATIONS = 'paperlessRegistrations';
export const GRAPH_TYPE_PAPERLESS_WAIT_TIME = 'paperlessWaitTime';
export const GRAPH_TYPE_PAPERLESS_TIME_SAVED = 'paperlessTimeSaved';
export const GRAPH_TYPE_TELEMED_BOOKINGS = 'telemedVolume';
export const GRAPH_TYPE_TELEMED_DAILY_ACTIVE_PROVIDERS = 'dailyActiveProviders';
export const GRAPH_TYPE_TELEMED_TIME_IN_CALL = 'telemedTimeInCall';
export const GRAPH_TYPE_TELEMED_PATIENT_DEVICES = 'patientDevices';

export const MARKET_DFW = 'DFW';

export const GUS_SLACK_ID = 'U0ZPV77CP';

export class LoginErrorMessages {
  static BAD_CREDENTIALS = 'Invalid username or password. Please try again!';
  static EXPIRED_TOKEN = "You've been logged out due to inactivity.";
  static GENERIC = 'Something went wrong. Please try again!';
  static NOT_SUFFICIENTLY_DIFFERENT = 'New password provided must be 4 characters different.';
  static INVALID = 'Current password provided is invalid.';
  static MATCHES_RECENT = 'Current password matches a recently used password.';
  static SSO_ENABLED = 'Please log in via the Google or Microsoft button below.';
}

export class SpecialtyTypes {
  static DENTIST = 'dentist';
  static URGENT_CARE = 'urgent_care';
}

export class InsuranceTypes {
  static MEDICAL = 'medical';
  static DENTAL = 'dental';
  static DENTAL_INSURANCE_PROFILE = 'dentalInsuranceProfile';
}

export class InsuranceCardSide {
  static INSURANCE_CARD_FRONT = 'front';
  static INSURANCE_CARD_BACK = 'back';
}

export class PaperworkTypes {
  static FACESHEET = 'facesheet';
  static REGISTRATION = 'registration';
  static KIOSK = 'kiosk';
}

export class PaperworkFieldTypes {
  static INPUT = 'input';
  static RADIO = 'radio';
  static SELECT = 'select';
  static CHECK_BOX = 'check_box';
  static BIRTH_DATE = 'birth_date';
  static RELATIONSHIP_TO_ACCOUNT = 'relationship_to_account';
  static PHARMACY_SEARCH = 'pharmacy_search';
  static PHONE = 'phone';
  static EMAIL = 'email';
  static SSN = 'ssn';
}

export class HandoffTypes {
  static PAPERWORK = 'paperwork';
  static VIDEO_VISIT = 'video_visit';
}

export class BirthSexOptions {
  static FEMALE = 'female';
  static MALE = 'male';
  static OTHER = 'other';

  static LIST = [BirthSexOptions.FEMALE, BirthSexOptions.MALE, BirthSexOptions.OTHER];
}

export class RaceOptions {
  static ASIAN = 'Asian';
  static BLACK = 'Black or African American';
  static HAWAIIAN_PACIFIC = 'Hawaiian or Pacific Islander';
  static NATIVE_AMERICAN = 'American Indian or Alaska Native';
  static OTHER = 'Other';
  static PREFER_NOT_TO_ANSWER = 'Prefer not to answer';
  static UNKNOWN = 'Unknown';
  static WHITE = 'White';

  static get all() {
    return [
      RaceOptions.ASIAN,
      RaceOptions.BLACK,
      RaceOptions.HAWAIIAN_PACIFIC,
      RaceOptions.NATIVE_AMERICAN,
      RaceOptions.OTHER,
      RaceOptions.PREFER_NOT_TO_ANSWER,
      RaceOptions.UNKNOWN,
      RaceOptions.WHITE,
    ];
  }
}

export class EthnicityOptions {
  static HISPANIC_LATINO = 'Hispanic/Latino';
  static NOT_HISPANIC_LATINO = 'Not Hispanic/Latino';
  static PREFER_NOT_TO_ANSWER = 'Prefer not to answer';

  static get all() {
    return [
      EthnicityOptions.HISPANIC_LATINO,
      EthnicityOptions.NOT_HISPANIC_LATINO,
      EthnicityOptions.PREFER_NOT_TO_ANSWER,
    ];
  }
}

export enum EligibilityStatuses {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  NOT_FOUND = 'Not Found',
  NOT_SUBMITTED = 'Not Submitted',
}

export const IMAGE_UPLOAD_STATUSES = {
  uploadFailed: { upload_status: 'upload failed' },
  uploaded: { upload_status: 'uploaded' },
  uploadFailedCompressed: { upload_status_compressed: 'upload failed' },
  uploadedCompressed: { upload_status_compressed: 'uploaded' },
};

export const IMAGE_SOURCES = {
  insurance: 'insurance',
};

export const GROUPS_CONTACTLESS_TIME_CONSTRAINTS: Record<string, string[]> = {
  [TOTAL_ACCESS_URGENT_CARE_GROUP_ID]: ['06:00:00', '08:00:00'],
};

export const ServiceIdentifiers = {
  ATHENA: { id: 8, label: 'Athena' },
  DOCUTAP: { id: 9, label: 'DocuTap' },
  DRCHRONO: { id: 7, label: 'DrChrono' },
  REDOX: { id: 10, label: 'Redox' },
  MEDSTAR: { id: 11, label: 'MedStar' },
  PRACTICE_VELOCITY: { id: 12, label: 'Practice Velocity' },
  ACUITY: { id: 14, label: 'Acuity' },
  CARBON_HEALTH: { id: 16, label: 'Carbon Health' },
  GOHEALTH: { id: 17, label: 'GoHealth' },
  ECW: { id: 18, label: 'eCW' },
  ZOOMCARE: { id: 19, label: 'Zoomcare' },
  EPIC: { id: 21, label: 'Epic' },
};

export class ContactTypes {
  static GUARDIAN = 'guardian';
  static EMERGENCY = 'emergency';
}

export const CONTACT_RELATIONSHIP_TYPES = [
  'Self',
  'Spouse',
  'Parent',
  'Mother',
  'Father',
  'Other',
  'Grandparent',
  'Sibling',
];

export class SolvPlatformTypes {
  static PROVIDER_GROUP = 'provider_group';
}

export const STATUS_CHANGE_ERROR_DISPLAY_DURATION = 5000;

export type WhiteLabelPaletteName = keyof typeof whiteLabelPalettes;

//TODO: When these are added to @solvhealth/tokens this can be removed to use those instead
//Color Palette options for WhiteLabeling
export const whiteLabelPalettes = {
  whiteout: {
    name: 'Whiteout',
    palette: {
      '100': '#FFFFFF',
      '200': '#FFFFFF',
      '300': '#FFFFFF',
      '500': '#FFFFFF',
      base: '#FFFFFF',
    },
  },
  crunchberry: {
    name: 'Crunchberry',
    palette: {
      '100': '#FDECF4',
      '200': '#E5A5C5',
      '300': '#F50B7C',
      '500': '#CC0D71',
      base: '#E30C79',
    },
  },
  cranberry: {
    name: 'Cranberry',
    palette: {
      '100': '#FFF0F0',
      '200': '#DB9696',
      '300': '#E40037',
      '500': '#A80003',
      base: '#B8002C',
    },
  },
  pomegranate: {
    name: 'Pomegranate',
    palette: {
      '100': '#FFE5E5',
      '200': '#DB9A9A',
      '300': '#E21700',
      '500': '#990F00',
      base: '#BD1300',
    },
  },
  papaya: {
    name: 'Papaya',
    palette: {
      '100': '#FFEDE3',
      '200': '#E3B093',
      '300': '#EA5500',
      '500': '#8A3200',
      base: '#BD4500',
    },
  },
  tangerine: {
    name: 'Tangerine',
    palette: {
      '200': '#FFF2E5',
      '100': '#E2BB93',
      '300': '#DA7500',
      '500': '#7A4100',
      base: '#A85A00',
    },
  },
  asiago: {
    name: 'Asiago',
    palette: {
      '100': '#FFF0C9',
      '200': '#D9C38C',
      '300': '#C89200',
      '500': '#664B00',
      base: '#8A6500',
    },
  },
  avocado: {
    name: 'Avocado',
    palette: {
      '100': '#EBFFCB',
      '200': '#AAC381',
      '300': '#6AAD00',
      '500': '#395C00',
      base: '#4B7A00',
    },
  },
  kiwi: {
    name: 'Kiwi',
    palette: {
      '100': '#E1FFF4',
      '200': '#8BC8B2',
      '300': '#00AD6E',
      '500': '#00613D',
      base: '#008051',
    },
  },
  cucumber: {
    name: 'Cucumber',
    palette: {
      '100': '#E1FFFB',
      '200': '#8CC8C1',
      '300': '#00A1AA',
      '500': '#005C61',
      base: '#007E85',
    },
  },
  braspberry: {
    name: 'BRaspberry',
    palette: {
      '100': '#E1F4FF',
      '200': '#94BBD0',
      '300': '#0087C1',
      '500': '#00567A',
      base: '#0072A3',
    },
  },
  blueberry: {
    name: 'Blueberry',
    palette: {
      '100': '#EBF7FF',
      '200': '#82B6D8',
      '300': '#5C8AFF',
      '500': '#003EDB',
      base: '#2462FF',
    },
  },
  blackberry: {
    name: 'Blackberry',
    palette: {
      '100': '#E2DFFF',
      '200': '#A09BD7',
      '300': '#7568FF',
      '500': '#1400F0',
      base: '#5242FF',
    },
  },
  taro: {
    name: 'Taro',
    palette: {
      '100': '#F0E0FF',
      '200': '#AE8CCE',
      '300': '#AD5CFF',
      '500': '#6300C7',
      base: '#8C1AFF',
    },
  },
  eggplant: {
    name: 'Eggplant',
    palette: {
      '100': '#F8E5FF',
      '200': '#C58EDA',
      '300': '#C62CFF',
      '500': '#7F00AD',
      base: '#A800E6',
    },
  },
  beet: {
    name: 'Beet',
    palette: {
      '100': '#FFE2F7',
      '200': '#DFA0CD',
      '300': '#DC009E',
      '500': '#94006A',
      base: '#A80079',
    },
  },
  plum: {
    name: 'Plum',
    palette: {
      '100': '#DADEEC',
      '200': '#8E9DCF',
      '300': '#576BCD',
      '500': '#334184',
      base: '#4655A0',
    },
  },
  licorice: {
    name: 'Licorice',
    palette: {
      '100': '#EDEDED',
      '200': '#9E9CAF',
      '300': '#636E7D',
      '500': '#3C424B',
      base: '#48505B',
    },
  },
  zoomcare: {
    name: 'ZoomCare',
    palette: {
      '100': '#C7F0F8',
      '200': '#00D5FF',
      '300': '#2CA0C3',
      '500': '#1A3147',
      base: '#2E414A',
    },
  },
} as const;
