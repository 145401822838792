import 'antd/dist/antd.css';
import 'isomorphic-fetch';
import './../bundle.scss';

import { parse } from 'cookie';
import { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Global from '../components/Global';
import { Startup } from '../components/Startup';
import { UiConfigProvider } from '../components/UiConfigContext';
import {
  ANALYTICS,
  INSPECTLET_ENABLED,
  isDev,
  NOTIFICATIONS_APPLICATION_SERVER_KEY,
} from '../config';
import NotificationProvider from '../core/notifications/NotificationsProvider';
import { registerServiceWorker } from '../core/serviceWorker';
import { persistor, store } from '../store/configureStore';
import { getUiConfig } from './api/ui-config';
import { UiConfig } from '../components/UiConfig';

const swRegistration = registerServiceWorker('/serviceWorker.js');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  uiConfig: UiConfig;
};

function MyApp({ Component, pageProps, uiConfig }: AppPropsWithLayout) {
  /**
   * see: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
   * for allowing nested layouts that can be defined on a per-page basis
   */
  const getLayout = Component.getLayout ?? ((page) => page);
  const ComponentWithLayout = getLayout(<Component {...pageProps} />);

  return (
    <UiConfigProvider value={uiConfig}>
      <div style={{ height: '100%' }}>
        <Head>
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
            rel="stylesheet"
          />
          {ANALYTICS.enabled && typeof window !== 'undefined' && (
            <script
              dangerouslySetInnerHTML={{
                __html: `!function (){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="3.1.0";
            analytics.load("${ANALYTICS.segment.apiKey}");
            }}();`,
              }}
            />
          )}
          {INSPECTLET_ENABLED &&
            typeof window !== 'undefined' &&
            ANALYTICS.inpsectletPaths.some((pathRegex) =>
              pathRegex.test(window.location.pathname)
            ) && (
              <script
                id={'inspectletjs'}
                dangerouslySetInnerHTML={{
                  __html: `window.__insp = window.__insp || [];
        __insp.push(['wid', 1391777498]);
        (function() {
          function ldinsp(){if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
          setTimeout(ldinsp, 500); document.readyState != "complete" ? (window.attachEvent ? window.attachEvent('onload', ldinsp) : window.addEventListener('load', ldinsp, false)) : ldinsp();
        })();`,
                }}
              ></script>
            )}
        </Head>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <NotificationProvider
              applicationServerKey={NOTIFICATIONS_APPLICATION_SERVER_KEY}
              swRegistration={swRegistration}
            >
              <QueryClientProvider client={queryClient}>
                <Startup>
                  <Global />
                  {ComponentWithLayout}
                </Startup>
                {isDev() && <ReactQueryDevtools initialIsOpen={false} />}
              </QueryClientProvider>
            </NotificationProvider>
          </PersistGate>
        </Provider>
      </div>
    </UiConfigProvider>
  );
}

/**
 * Keep this here to disable static size optimization so we can use runtime variables.
 * @param appContext
 */
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  let cookies = parse(appContext.ctx.req?.headers?.cookie ?? '');
  const uiConfig = getUiConfig(cookies['ui-config']);
  return { ...appProps, uiConfig };
};

export default MyApp;
