import { serialize } from 'cookie';
import cookieSignature from 'cookie-signature';
import type { NextApiRequest, NextApiResponse } from 'next';
import { defaultUiConfig, UiConfig, uiConfigSchema } from '../../components/UiConfig';
import { isDev } from '../../config';
import { getSharedSessionSecret } from '../../core/auth/universalAuthSession';

export function getUiConfig(cookie: string): UiConfig {
  try {
    // cookie is in a form of [base64string].[signature]
    // we don't care about the signature on this cookie, (as we can't easily verify it)
    let base64String = cookie.split('.')[0];
    const decoded = Buffer.from(base64String, 'base64').toString('utf8');
    return uiConfigSchema.parse(JSON.parse(decoded));
  } catch (e) {
    return defaultUiConfig;
  }
}

/** Build the cookie value to be stored in the shared session */
function mergeNewUiConfigValues(req: NextApiRequest): UiConfig {
  const result = uiConfigSchema.deepPartial().parse(JSON.parse(req.body));

  // merge the existing config with the new config, including tableColumnViews
  const existingConfig = getUiConfig(req.cookies?.['ui-config'] ?? '');

  const mergedConfig = uiConfigSchema.parse({
    ...existingConfig,
    ...result,
    tableColumnViews: {
      ...existingConfig.tableColumnViews,
      ...result.tableColumnViews,
    },
  });

  // serialize the config into a cookie
  return mergedConfig;
}

export default async function storeUiConfig(req: NextApiRequest, res: NextApiResponse) {
  let cookieValue: UiConfig;
  try {
    cookieValue = mergeNewUiConfigValues(req);
  } catch (e: any) {
    console.warn('Invalid token:', e);
    return res.status(400).send({ error: isDev() ? e.message : 'Invalid token' });
  }

  try {
    const jsonString = JSON.stringify(cookieValue);
    const maxAge = 60 * 60 * 24 * 365; // 1 year
    const encoded = Buffer.from(jsonString).toString('base64');
    const signed = cookieSignature.sign(encoded, getSharedSessionSecret());

    const serializedCookies = [
      serialize('ui-config', signed, {
        path: '/',
        maxAge,
        httpOnly: false,
        sameSite: 'lax',
        secure: process.env.NODE_ENV !== 'development',
      }),
    ];

    res.setHeader('Set-Cookie', serializedCookies);

    return res.send('');
  } catch (e: any) {
    console.error(e);
    return res.status(500).send({ error: isDev() ? e.message : 'Internal server error' });
  }
}
