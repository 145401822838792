import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

// Define the shape of our UI config
interface UiConfig {
  tableColumnViews: Record<string, Record<string, boolean | undefined>>;
  useNewNav: boolean;
}

// Define the shape of our context
interface UiConfigContextType {
  uiConfig: UiConfig;
  setUiConfig: (newConfig: Partial<UiConfig>) => void;
}

// Create the context with a default value
export const UiConfigContext = createContext<UiConfigContextType | undefined>(undefined);

// Provider component
export const UiConfigProvider: React.FC<{ children: ReactNode; value: UiConfig }> = ({
  children,
  value,
}) => {
  const [uiConfig, setUiConfig] = useState<UiConfig>(value);

  useEffect(() => {
    console.log('Booting UI Config Context');
  }, []);

  async function updateUiConfig(newConfig: Partial<UiConfig>) {
    setUiConfig((prevConfig) => ({
      ...prevConfig,
      ...newConfig,
      tableColumnViews: {
        ...prevConfig.tableColumnViews,
        ...newConfig.tableColumnViews,
      },
    }));

    await fetch('/api/ui-config', {
      method: 'POST',
      body: JSON.stringify(newConfig),
    });
  }

  return (
    <UiConfigContext.Provider value={{ uiConfig, setUiConfig: updateUiConfig }}>
      {children}
    </UiConfigContext.Provider>
  );
};

// Custom hook for using the UI config
export const useUiConfig = () => {
  const context = useContext(UiConfigContext);
  if (context === undefined) {
    throw new Error('useUiConfig must be used within a UiConfigProvider');
  }
  return context;
};
